@media  (max-width: 1200px) { 

    .ReactTable .rt-thead.-header {
        text-align: center;
        font-size: 12px !important;
        padding: 1px !important;
        color: #515364 !important;
    }
    
    .ReactTable .rt-tbody {
        padding: 2px 15px !important;
        font-size: 12px !important;
    }
    
    .ReactTable .rt-tbody .rt-tr-group {
        border-bottom: solid 1px rgba(0,0,0,0.05);
        text-align: center;
        color: #4a4c5d !important;
        /* padding: 8px 0px; */
        margin: 0px !important;
    
    }
    
    .ReactTable .rt-thead .rt-resizable-header-content {
        /* overflow: hidden;
        text-overflow: ellipsis; */
        white-space: normal;
        margin: 0px !important;
        padding: 0px 5px!important;
    }
    
    .ReactTable .rt-th, .ReactTable .rt-td {
        white-space: normal !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0px!important;
        margin: 0px !important;

        /* width: min-content !important */

    }
    
    .ReactTable .rt-tbody {
        padding: 0px !important;
        /* font-size: 16px; */
    }

    /* .ReactTable .rt-resizer {

        width: 1px !important;
   
    } */
}