.header {
    display: flex;
    justify-content: end;
    position: absolute;
    width: 100%;
    background-color: black;
    color: white;
    padding: 20px 0px;
    
}

.header > div {
    padding: 0px 20px;
}

.header-left {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-left: 200px;
}

.header-right {
    width: 100%;
    display: flex;
    justify-content: space-around;

}


.modal {
    padding: 50px;
}


.m-menu {
    list-style-type: none;
    position: relative;
    text-align: center;
    z-index: 101;
  }

  .header_item {
    cursor: pointer;
  }
  /* .m-menu.-active .m-menu__icon path {
    fill: #0b3895;
  }
  .m-menu.-active .m-menu__list {
    transform: scale(1);
  } */
  /* .m-menu__list {
    position: absolute;
    text-align: left;
    top: 50%;
    left: 50%;
    z-index: 101;
    padding: 0;
    border-radius: 8px;
    background-color: #fff;
    transition: 0.25s ease all;
    transform: scale(0);
    transform-origin: 0 1;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  } */
  /* .m-menu__item {
    display: block;
    width: 100%;
    padding: 12px;
    color:black;
  }
  .m-menu__item:hover {
    color: #0b3895;
  }
  .m-menu__link {
    width: 100%;
    padding: 4px;
    display: inline-block;
    white-space: pre;
  }
  .m-menu__link:hover {
    color: #0b3895;
    text-decoration: none;
  } */

  .logo-small-2x {
    width: 25px !important;
    height: 25px !important;
  }


  .header_box {
    display: flex;
    align-items: center;
  }

  .header_box > img {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }

  @media  (max-width: 900px) {  

    .header {
      padding: 15px 0px !important;
      
  }
  
  .logo-small-2x {
    width: 22px !important;
    height: 22px !important;
  }

   }


   .boxheader {
    display: flex;
    z-index: 800;

   }

   .boxheader > div {
    margin: 0px 5px;
   }

