
/* 
.deposit-background {
    background-color: #dadadd75;

} */
.deposit_panel_main {
    display: flex;
    justify-content: flex-start;
    padding-left: 50px;
    width: auto;
    background-color: #F6F7FB;;
    
  
}

.deposit_panel_main_first {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1.2px solid 	#E7ECF4;
    border-radius: 5px;
    margin-top: 15px;
    width: 30vw;
}

.deposit_panel_main_first > h3 {
    color: #515365;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 0 !important;
 }

 .deposit_panel_main_first > h1 {
    background-color: #f7fafc;;
    font-size: 31px;
    color: #000000;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
}

.deposit_panel_main_first_text {
    color: rgba(128, 128, 128, 0.8);
    font-size: 14px;
    padding: 15px 10px;
    text-align: center;
}

.deposit_panel_main_first_op {
    padding: 20px 10px;
    width: 80%;
    border-bottom: 1px solid #E7ECF4;
    text-align: center;
    color: #515365;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
}

.deposit_panel_main_second {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 45vw;
    /* width: auto; */
    background-color: white;
    border: 1px solid #dadadd75;;
    border-radius: 5px;
    margin-top: 15px;
}

.deposit_panel_main_second_main {
    display: flex;
    align-items: center;
    padding: 30px 30px;
    justify-content: space-between;
}

.deposit_panel_main_second_block  > img {
    filter: invert(0) sepia(100%) saturate(7227%) hue-rotate(110deg) brightness(541%) contrast(0%);
}

.logo-big-2x {
    width: 35px;
    height: 35px;
}

.deposit_panel_main_second_block {
    display: flex;
    align-items: center;

    width: 35vw;
}

.deposit_panel_main_second_inner {
    display: flex;
    flex-direction: column;
    margin-left: 2vw;
}


.deposit_panel_main_second_inner > div {
    font-size: 16px;
    color: #515364 !important;
    line-height: 1.5;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin-bottom: 5px;
}


.deposit_panel_main_second_inner > span,
.dataTime  {
    color: rgba(128, 128, 128, 0.8);
    font-size: 14px;
    text-align: start;
    margin: 0;
 }


.deposit_panel_main_second_title {
    font-size: 18px;
    padding: 20px 0  20px 1.1vw; 
    border-bottom: 1px solid #dadadd75;;;
    color: #515365;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 0 !important;
    margin: 0;
}



.deposit_panel_main_button_block {
   padding: 25px 10px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-around;
   
}

.custom-button-icon {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    text-align: center;
    border: 1px solid rgba(128, 128, 128, 0.307);
    border-radius: 4px;
    padding: 8px 12px;
    margin: 7px 7px;
}

.custom-button-icon > div {
    padding-left: 5px;
    color: #515365;
}

.block {
    background-color: #00c292;
    padding: 13px;
    border: 1px solid inherit;
    border-radius: 5px;
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;
    
}

.blue {
    background-color: #ffffff;
    color: #000000;
}

.pink {
  background-color: #ffffff;
    color: #000000;
}

.green {
    background-color: #ffffff;
    color: #000000;
}

.purple {
    background-color: #ffffff;
    color: #000000;
}

.deposit_bottom_panel {
    background-color: #F6F7FB;;
    padding: 20px 0px 20px 450px;
    height: 30vh;
    display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.deposit_bottom_panel > h2{

    font-size: 22px;
    color: #515365;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
   
}

.deposit_bottom_panel > div {
  padding-left: 80px;
   
   
}

 
@media  (max-width: 920px) {
    .deposit_panel_main {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        padding-left: 0px !important;
    }

    .deposit_panel_main_first {
        margin-top: 15px;
        width: 90%;
    }

    .deposit_panel_main_second {
        width: 90%;
        margin-left: 0;
    }

    .deposit_panel_main_second_main {
        flex-direction: column;
    }

    .deposit_bottom_panel {
        padding-right: 0 !important;
        padding: 30px 10px 30px 10px;
        text-align: center;
    }

    .deposit_panel_main_second_block {
        width: 100%
    }

    .deposit_panel_main_second_block > img {
        margin-right: 3vw;
    }

    .block {
        margin-top: 15px;
    }

    .block-count {
        margin-top: 15px;
    }

    .deposit_panel_main_second_title,
    .deposit_panel_main_second_ref_title {
        text-align: center;
    }

    .deposit_bottom_panel {
        padding: 0;
        width: 100%
    }
    
    .deposit_bottom_panel_table {
        padding: 0 !important;
        width: 95% !important;
    }

 }


.deposit_bottom_panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:0;
    padding-right: 15vw;
    height: 100%;
}

.deposit_bottom_panel_table {
    padding: 0 !important;
    height: 100%;
    width: 75vw;
}