.clientPanel {
padding-top: 60px;
width: 100%;
}

.page-titles {
    padding-bottom: 8px;
    background: #ffffff;
    display: flex;
    padding-left: 50px;
    width: 100%;
}

.flex-boxx {
    display: flex;
    flex-direction: column;
    width: 60%;

}

.text-themecolor-2 {
    color: #515365;
}    



.text-themecolor {
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 10px;
    color: #515365;
   
}

.breadcrumb {
    display: flex;
    align-items: center;
    padding-left: 4px;
}

.breadcrumb-item {
    /* margin-top: 3.4px; */
    padding: 0 4.5px;
    font-size: 14px;
    font-style:oblique;
    color: #99abb4;
}

.next {
    color: #99abb4;
    /* padding-bottom: 20px; */
    font-size: 10px;
    margin: 0 3px;
}

.main-content-cleint-wrapper {
    display: flex;
    flex-direction: column;
    /* padding: 7px 100px 5px 200px; */

    background-color: #F6F7FB;
    padding-left: 50px;
    margin-bottom: 50px
    
}

.main-content-client {
   display: flex;

   margin: 0 5px 0 0px;
  
}

@media  (max-width: 1200px) {
    .main-content-client {
        display: flex;
        flex-direction: column;
       
     }
  }

.left {
   
    /* min-width: 360px; */
}

.left-inside {
    display: flex;
    flex-direction: column;
}










@media  (max-width: 1200px) {
    .left-inside {
        display: flex;
        flex-direction:  row;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 67vw;

    
    }
.wrapper-radio {
padding: 20px 50px;

}

.right {
    width: 62.2vw !important;
 border: 2px solid green;
 margin-left: 25px !important;
    background-color: white;
  }



  }


@media  (max-width: 1043px) { 

    .left-inside {
        display: flex;
        flex-direction:  row;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 60vw;

    
    }

    .right {
        width: 55vw !important;
      }

      .wrapper-radio {
        padding: 20px 100px;
        }

        .right-panel-flex {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 20px 20px 0 20px;
        }

      
        
        
        .right-panel-flex-left-inside {
         
            padding-left: 15vw !important;
            width: 100% !important;
            display: flex;
      
        }
        
}


@media  (max-width: 920px) { 

    .main-content-cleint-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
    
        background-color: #dadadd75;
        
    }


    .main-content-client {
        display: flex;
   
        margin: 0 !important;
        align-items: center !important;
       
     }

    .left-inside {
        display: flex;
        flex-direction:  row;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100% !important;

    
    }

    .right {
        width: 93% !important;
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        margin: 0 !important;
  
      }

      .wrapper-radio {

        width: 47vw !important;
        padding:  10px 10px 0 10px !important;
        display: flex!important;
        justify-content: center !important;
        margin-bottom: 10px !important;

        }

        .radio-title {
            font-size: 13px;
            width: 90%;
            text-align: center;
            padding: 5px 0px !important;

        }

        .radio-icon-box {
         display: flex;
         flex-direction: column;
         align-items: start;
        }

        .radio-list {
            font-size: 12px;
            /* padding-top: 10px !important; */
            padding: 6px 0px !important;
        }

        .checkbox-field {
            padding: 5px 5px !important;
        
        }

        .page-titles {
    background: #ffffff;
    padding: 8px 0 !important;
    display: flex;
    margin-left: 0px !important;
    width: 100% !important;
    align-content: center;

}

.flex-boxx {
    width: 100% !important;
}

.breadcrumb {
    margin-top: 3px;
    margin-left: 5px;
    /* display: flex !important; */
    justify-content: flex-start!important;
    height: 25px;
    /* align-items: center; */


    /* padding-left: 6px; */
}

.breadcrumb-item {
    font-size: 12px;
    font-style:oblique;
    color: #99abb4;
    text-align: left!important;
    padding: 0px !important; 
    padding-right: 0px!important;

    /* height: 25px; */
}



.text-themecolor {
    font-size: 16px;
    margin: 0;
    padding-left: 7px;
    color: #515365;
   
}

.text-themecolor-2 {
    font-size: 20px;
    margin: 0;
    padding: 15px 0 10px 4.2px;
    color: #515365;
    text-align: center;
   
}


.right-panel-flex {
    display: flex;
    flex-direction: column;
    margin: 20px 20px 0 20px;
}

.right-panel-flex-right {
    text-align: center;
    margin-top: 15px;
}

.right-title {
    flex-direction: column;
    text-align: center;
    
}

.text-block {
    padding: 20px 10px;
}

.right-panel {
    width: 95%;
    margin: 40px 0px !important; 
    display: flex;
    flex-direction: column;
    align-self: center;

}
   

}



@media  (max-width: 450px) { 
    .breadcrumb-item {
        min-width: min-content!important;
        max-width: 160px!important;
        }
 }










.text-block {
    padding: 20px 20px;
    margin-top: 30px;
    border-top: 1px solid rgba(0, 128, 0, 0.259);
    color: #515365;
    font-size: 15px;
}



.right {
  width: 50vw;
  margin-left: 20px;

  border: 1.4px solid #E7ECF4;
  border-radius: 12px;

  background-color: white;
  overflow: hidden;
}

.checkbox-field {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.custom-checkbox {
    position: absolute;
  display:none;
}

.wrapper-radio {
    position: relative;
    background-color: white;
    width: 20vw;
    margin-bottom: 20px;
    border: 1px solid #E7ECF4;
    border-radius: 7px;
    box-sizing: border-box;

}

.blue-box {
    position: relative;
    background-color: #000000!important;
    color: white !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    transition: all 0.2s ease-out;
    box-sizing: border-box;

}


.right-title {
    background-color: #F5F5F5;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    font-size: 18px;
    font-weight: bold;
    color: #515365;
}

.right-panel {
    border: 1px solid #E7ECF4;
    border-radius: 5px;
    text-align: center;
    margin: 40px 80px;
    padding: 0px 0px;
    
}

.right-panel-title {
    padding: 10px 0;
    color: #515365 !important;
    font-size: 18px;

}

.right-panel-amount {
    padding: 20px 0;
    background-color: #F5F5F5;
    color: black;
    font-size: 31px;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;

}

.right-input-field {
    display: flex;
    flex-direction: row;
    padding: 0 30px;
}

.right-input-field > div {
    border: 1px solid #e6ecf5;
    box-sizing: border-box;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff9800;
    font-weight: bold;
    font-size: 22px;
    border-left: none;
}

.right-input-field > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border: 1px solid #e6ecf5;
    outline: none;
}

.right-input-field > input:focus {
    border: 1px solid #2cc2f3 !important;
}

.small {
    font-size: 10px;
    opacity: 50%;
}

.btn-dep {
    margin-top: 10px;
    text-shadow: none;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 10%);
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff !important;
    background-color: #8dbf42;
    border-color: #8dbf42;
    width: 100%;
    padding: 11px 14px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
   
}

.btn-dep:hover {
    background-color: #8dbf42f0;
    border-color: #8dbf42ed;
}


.right-panel-info {
    font-size: 18px;
    opacity: 70%;
    text-align: center;
    padding-bottom: 15px;
    margin: 0px 30px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.209);
}

.right-panel-flex {
    display: flex;
    justify-content: space-around;
    margin: 20px 20px 0 20px;
}



.right-panel-flex-right {
    display: flex;
    flex-direction: column;
    border: 1px solid grey;
}

.right-panel-flex-right > div {
    padding: 20px 15px !important;
}





.logo-big-icon {
    width: 35px;
    height: 35px
}

.logo-sm-icon {

    width: 15px;
    height: 15px
}

.radio-list {
    display: flex;
    justify-content: flex-start;
    padding: 12px 0px;
}

.radio-title {
    /* width: 80%; */
    text-align: center;
    padding: 12px 0px;

   display: flex;
   align-items: center;
   font-size: 16px !important;
   font-weight: bolder;
   position: relative
}


.radio-title > img {
    margin-right: 15px;
    
}

.radio-list > img {
    display: flex;
    justify-content: flex-start;
    margin-right: 4px;
}

.radio-list > span {
    font-size: 13px;
    
}

.right-panel-flex-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

}


.right-panel-flex-left-inside {
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px 2vw;
    width: 15vw;
    border: 1.8px solid #F3F6FA;
    
}



.right-panel-flex-left-inside-div {
    margin-left: 25px;
 
}

.right-panel-flex-left-inside-div > span{
    color: #131a2d !important;
    font-size: 14px !important;
}

.right-panel-flex-left-inside-div > div{
    font-size: 14px;
    font-weight: bold;
    margin-top: 2.5px
}

.text-green {
    color: #ff9800;
}

.font-max {
    font-size: 16px !important;

}

.right-panel-flex-right {
    border: 2px solid #F3F6FA;
    border-radius: 5px;
    padding: 5px 20px;
}


.right-panel-flex-right-inside > div {
    color: #131a2d !important;
    font-size: 14px !important;
}

.right-panel-flex-right-inside > span {
    font-size: 16px;
    font-weight: bold;
    margin-top: 2.5px
}


.btn-collapse {

    text-shadow: none;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.049);
    border-radius: 3px;
    text-align: center;
    vertical-align: middle;
    border: none;
    line-height: 1.5;
    color: rgba(37, 37, 37, 0.827) !important;
    background-color: #ffffff5b;
    width: 100%;
    padding: 7px 14px;
    font-size: 16px;
    letter-spacing: 1.2px;
    cursor: pointer;
   
}

.btn-collapse:hover {
    background-color: #edebeba3;
    color: white;
}

.somepoint {
    height: 10px
}

.ReactCollapse--collapse {
  transition: height 500ms;
}