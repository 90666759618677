

   .chat {
    position: fixed;
      bottom: 0;
    
      /* left: 320px; */
      right: 0;
      width: 100vw;
    z-index: 1000;
    width: 30vw;
    border: 2px solid rgb(248, 248, 248);
  }
  
  
  .chat_icon {
    position: fixed;
    bottom: 30px;
    right: 15px;
    width: 60px;
    height: 60px;
    cursor: pointer;
  
  }
  
  .new_msg {
    position: fixed;
    bottom: 70px;
    right: 6px;
  
    width: 25px;
    height: 25px;
    z-index: 2000;
  }
  


  .close {
    position: fixed;
    width: 35px;
    height: 35px;
    z-index: 2000;
    top: 12px;
    right: 1vw;
    cursor: pointer;
  }
  
  .str-chat__header-hamburger  {
   
    display: none;
  }
  /* 
  .str-chat__message {
  
    margin: 5px 0;
    border-radius: 3%;
    background-color: rgba(198, 251, 233, 0.605);
  } */
  
  .str-chat__message-simple-text-inner, .str-chat__message-text-inner {
    background-color: rgba(92, 153, 161, 0.3);
  }
  
  
  
  
  
  
  
  .str-chat__avatar-fallback {
    background-color: #4aa4af;
  }
  
  
  
  
  
  @media  (max-width: 979px) {

    .chat {
        position: fixed;
          bottom: 0;
          left: 0;
          width: 100vw;
        z-index: 1000;
        width: 90vw;
      
      }
  
      
    .close {
      position: fixed;
      width: 28px;
      height: 28px;
      z-index: 2000;
      top: 15px;
      right: 11.5vw;
    }
  }
  
  @media  (max-width: 790px) {
    .close {
      position: fixed;
      width: 26px;
      height: 26px;
      z-index: 2000;
      top: 13px;
      right: 11.9vw;
    }
  }
  
  @media  (max-width: 650px) {
    .close {
      position: fixed;
      width: 26px;
      height: 26px;
      z-index: 2000;
      top: 16px;
      right: 12.2vw;
    }
  }
  
  @media  (max-width: 500px) {
    .close {
      position: fixed;
      width: 22px;
      height: 22px;
      z-index: 2000;
      top: 10px;
      right: 12.6vw;
    }
  }
  
  @media  (max-width: 400px) {
    .close {
      position: fixed;
      width: 21px;
      height: 21px;
      z-index: 2000;
      top: 9px;
      right: 11vw;
    }
  }
  
  
  /* @media  (max-width: 979px) {
    .close {
      position: fixed;
      width: 25px;
      height: 25px;
      z-index: 2000;
      top: 5px;
      right: 11vw;
    }
  } */

  .str-chat__message-name, .str-chat__message-simple-name {
    display: none;
  }