
.modal-main {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

 

}

.form-container {
    display: block;
    width: 30%;
    padding: 25px;
    background: #fff;
    text-align: center;
    border-radius: 15px;
    border: 1px solid #e0e6ed;
    box-shadow: 0px 20px 40px 0 rgb(0 0 0 / 50%);

}

h1 {
    font-size: 32px;
    color: #3b3f5c;
    font-weight: 500;
    line-height: 1.2;
}


.btn {
    text-shadow: none;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 10%);
    text-align: center;
    vertical-align: middle;

    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff !important;
    background-color: #8dbf42;
    border-color: #8dbf42;
    /* align-self: center; */
    width: 100%;
    padding: 11px 14px;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}


.logo-big {
    width: 100px;
    height: 100px;
}