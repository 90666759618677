.settingBox {
    padding: 8px;
    margin-top: 4px;
}

.padding_top {
   padding-top: 4px;
}

.margin_top_input {
   margin-top: 4px;
}


.margin_top {
   margin-top: 10px;
}

.settingBox_inner {
    padding-bottom: 14px;
    padding-top: 14px;
    border-bottom: 1px solid #37373841;
    
}

.settings_big_text {
   font-size: 20px;
   color: #282932c5;
}



.partner-box {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}




.deposit_panel_main_first >h3 {
 padding: 0px 3vw;
}

.partner-blue-box {
    margin-top: 20px;
    background-color: #ff9800;
    border: 1px solid #ff9800;
    border-radius: 5px;
    width: 100%;
    color: white;
    text-align: center;
    padding: 0px 10px 25px 10px;
}

.partner-beige-box {
    margin-top: 20px;
    background-color: #FDF2D1;
    border: 1px solid #FDF2D1;
    border-radius: 5px;
    width: 100%;
    color: #806622;
    text-align: center;
    padding: 0px 10px 5px 10px;
}

.partner-blue-box >h4 {
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
    color: white !important
 }

 .bold {
    font-weight: bold !important;
 }

 .partner-blue-icon-box {
    display: flex;
  justify-content: center;
  align-items: center;

 }

 .partner-blue-icon-box > img {
    filter: grayscale(1) invert(1);
 }

 .partner-box-text-block {
    padding: 20px 20px 0 20px;
    font-size: 15px;
    text-align: left;
    color: #888da8;
    line-height: 1.5;
    letter-spacing: 0.2px;
 }

 .span-gray-bolder {
    font-weight: bolder;
    color: #888da8;
 }

 .span-green {
    color: #37c936 !important;
    font-weight: 500 !important;
 }

 .last {
    margin-bottom: 20px;
 }

 .deposit_panel_main_second_ref {
    display:  flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
 }

 .deposit_panel_main_second_ref_title {
    padding: 20px 0;
    color: #868e96 !important;
    font-size: 18px !important;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
 }

 .deposit_panel_main_second_ref_link {
    padding: 20px 0;
    font-size: 19px !important;
    background: #f7fafc;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
    color: #13afeb;
    width: 100%;
    text-align: center;
 }

 .deposit_panel_main_second_ref_button {
    display: flex;
    align-items: center;

    margin-bottom: 25px;
    margin-top: 15px;
    color: #515365;
    background-color: #ffffff;
    border: 1.2px solid #d4deee;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    font-size: 13px;
    padding: 10px 20px;
    cursor: pointer;
   
 }


 .deposit_panel_main_second_ref_button > img {
    margin-right: 6px
 }

 .block-count {
    /* background-color: #00c292; */
    padding: 10px 17px;
    border: 1px solid inherit;
    border-radius: 5px;
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;
 }


 .copy-link {
   color: rgba(84, 171, 37, 0.8);
   font-size: 14px;
   text-align: start;
   margin: 0;
 }