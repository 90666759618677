.btn-sok {
    text-shadow: none;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 10%);
    text-align: center;
    vertical-align: middle;

    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff !important;
    background-color: #8dbf42;
    border-color: #8dbf42;
    /* align-self: center; */
    width: 100%;
    padding: 11px 14px;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}

.change-password {
    display: flex;
    flex-direction: column;


}

.changeInput {
    width: 100% !important;
    padding: 13px 0px 13px 0px;
    border: 1px solid #bfc9d4!important;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    min-width: auto!important;
    max-width: auto!important;
    margin-bottom: 10px;
 
}