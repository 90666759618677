.pagination-panel {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    align-items:  center;
}

.pagination-panel_button {
    padding: 10px 20px;
    margin: 0px 5px;
    border: 1px solid rgba(119, 111, 111, 0.341);
    background-color: rgba(119, 111, 111, 0.057);
    width: 100px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

.pagination-panel_page {
    padding: 4px 10px;
    font-size: 20px;
    border: 1px solid gray;
    border-radius: 100%;

}