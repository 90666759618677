.errorblock {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error_error {
    font-size: 20vw;
    font-weight: bold;
    color:rgb(253, 156, 64)
}

.errorcontent {
    font-size: 20px ;
    text-align: center;
    width: 70%;
    margin: 3px 0;
}

@media  (max-width: 800px) {

    .error_error {
        font-size: 25vw;
    }

    .errorcontent {
        font-size: 14px !important ;

    }
}