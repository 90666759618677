.success_email {
    color: rgb(0, 171, 0);
    font-size: 22px;
    font-weight: bold;
}

.success_email_text {
    margin: 20px 0;
}

.galaxy_logo {
    width: 200px;
    height: 200px;
}