.adminPanel2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 50px 0;

    font-size: 22px;

}

.bottomAdminPanel {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(128, 128, 128, 0.506);
    background-color: white;
    padding: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.bottomAdminPanel > h3 {
    width: 60%;
    text-align: center;
}


.handleDeposit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    border: 1px solid rgba(128, 128, 128, 0.517);
    border-radius: 6px;
    background-color: white;
}

.handleDeposit > span {
    text-align: center;
    padding: 15px 0px;
}

.adminModal {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 500
}

.adminModal > div {
   padding: 40px 60px;
   border: 1px solid rgba(128, 128, 128, 0.517);
   border-radius: 6px;
   background-color: white;
   display: flex;
   flex-direction: column;
   align-items: center;
   bottom: 400px;
   position: fixed;
   
}

