.m-menu.-active .m-menu__list {
    transform: scale(1);
  }
  .m-menu__list {
    position: absolute;
    text-align: left;
    /* top: 50%;
    left: 50%; */
    top: 30px;
    right: 0;
    /* right: 0px; */
    z-index: 101;
    padding: 0;
    border-radius: 8px;
    background-color: #fff;
    transition: 0.25s ease all;
    transform: scale(0);
    transform-origin: 0 1;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  .m-menu__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
    color:black;
    cursor: pointer;
    border-bottom: 1px solid #43434412;
    font-size: 14px;
  }
  .m-menu__item:hover {
    background-color: #c9c9c921;
  }
  .m-menu__link {
    width: 100%;
    padding: 4px;
    display: inline-block;
    white-space: pre;
  }
  .m-menu__link:hover {
    text-decoration: none;
   
  }

  .green-item {
    color: #ff9800!important;
}

  .logo-big {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }

  .logo-green {
    -webkit-filter: invert(48%) sepia(13%) saturate(7207%) hue-rotate(130deg) brightness(85%) contrast(50%);
    filter: invert(48%) sepia(13%) saturate(7207%) hue-rotate(130deg) brightness(0%) contrast(50%);
}