.createAccount {
    padding: 100px;
    border: 1px solid black;
}

.form-div {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
 

}

.form-container {
    display: block;
    width: 30%;
    padding: 25px;
    background: #fff;
    text-align: center;
    border-radius: 15px;
    border: 1px solid #e0e6ed;
    box-shadow: 0px 20px 40px 0 rgb(0 0 0 / 50%);

}

h1 {
    font-size: 32px;
    color: #3b3f5c;
    font-weight: 500;
    line-height: 1.2;
}

.signup-link {
 
    font-size: 13px;
    color: #888ea8;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 20px;
}

.signup-link > a {
    color: #1b55e2;
    /* border-bottom: 1px solid; */
}

.form-label {
    font-size: 10px;
    font-weight: 700;
    color: #3b3f5c;
    margin-bottom: 8px;
}

.field-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    align-items: start;
    padding-bottom: 27px;
}

input {
    padding: 13px 0px 13px 46px;
    border-bottom: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    min-width: 50px;
    max-width: 635px;
    width: 90%;
    -ms-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    color: #3b3f5c;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid #bfc9d4;
    /* padding: 13px 0px 13px 46px; */
}

.field-container > svg{
    position: absolute;
    top: 32px;
    left: 12px;
    color: #888ea8;
    fill: rgba(0, 23, 55, 0.08);
    width: 20px;
    height: 20px;
    z-index: 100;
}

.field-container > label{
    font-size: 10px;
    font-weight: 700;
    color: #3b3f5cc0;
    margin-bottom: 7px;
}





.btn {
    text-shadow: none;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 10%);
    text-align: center;
    vertical-align: middle;

    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff !important;
    background-color: #ff9800;
    border: 1px solid #ff9800;
    /* align-self: center; */
    width: 100%;
    padding: 11px 14px;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}


.error-wrapper {
    position: absolute;
    margin-top: 66px;
    font-size: 12px;
    color: rgba(253, 88, 88, 0.897);
}



