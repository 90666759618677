.bm-burger-button {
    position: fixed;
    width: 22px;
    height: 22px;
    left: 25px;
    top: 18px;
    
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: rgba(198, 176, 76, 0.768) !important
    
  }
  
  /* Color/shape of burger icon bars on hover*/
  /* .bm-burger-bars-hover {
    background: #a90000;
  } */

  .bm-cross-button {
    height: 25px;
    width: 25px;
    left: 300px  !important;
    
  }

  .bm-menu-wrap {
    transition: all 0.1s ease!important;
    /* transition: '' !important; */
  }

  
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;

  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 255;
    z-index: 30;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #000000;
    font-size: 1.15em;
    width: 110%;


  }



  
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    /* padding: 0.8em; */
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block; 
    

  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }


  .separator {
    border-top: 1px solid gray;
    margin-top: 16;
    margin-bottom: 16;
    opacity: 0.06;
    width: 100%
  }

  .logo-wrapper {
    padding: 20px 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #000000;
    outline: none !important;
    border: none !important;
  }

  .first > img {
    width: 200px;
    height: 200px;
    z-index: 1000 !important;
    padding-right: 30px !important;
  }

  .text-wrapper {
    color: white;
    padding-left: 10px;
    font-size: 22px;
    font-weight: 800;
    margin-left: 12px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  


  @media  (max-width: 350px) {  
    .logo-wrapper {
      padding: 10px 15px !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: center!important;
      align-items: center !important;
      background-color: #323542;
      outline: none !important;
      border: none !important;
   
  
    }
  
    .first > img {
      width: 120px;
      height: 120px;
      z-index: 1000 !important
    }

    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      width: 90vw !important;
      z-index: 30;
    }
    
    /* General sidebar styles */
    .bm-menu {
      background: #373a47;
      font-size: 1.15em;
      width: 100% !important;
  
  
    }

    .text-wrapper {
      /* color: white;
      padding-left: 10px; */
      font-size: 16px !important;
      font-weight: 700 !important;
      /* margin-left: 12px;
      letter-spacing: 0.2px;
      text-transform: uppercase; */
    }

    .bm-cross-button {
      height: 25px;
      width: 25px;
      left: 80vw !important;
      
    }

  }



  @media  (max-width: 500px) { 

    .first > img {
      width: 120px;
      height: 120px;
      z-index: 1000 !important
    }

  .bm-burger-button {
    position: fixed;
    width: 18px;
    height: 18px;
    left: 25px;
    top: 18px;
    
  }

}