.admin-header {
    width: auto;
    padding: 20px 30px;
}


.admin-header-inner {
    width: 100%;
    display:  flex;
    justify-content: flex-end;
}

.admin-header-inner > div {
    background-color: rgb(251, 251, 251);
    padding: 10px 10px;
    border: 1px solid rgba(79, 108, 79, 0.62);
    border-radius: 5px;
    margin: 0px 05px;
    color: rgb(74, 74, 74);
    cursor: pointer;
}