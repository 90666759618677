/* .deposit-funds-panel- {
    padding-top: 68px;
    padding-left: 50px;
    background-color: #dadadd75;

} */


.deposit-funds-panel-wrapper {
    background-color: #F6F7FB;
    padding-top: 30px;
    /* height: 100vh; */
}


.deposit-funds-panel {
  border: 1.3px solid #e6ecf5;
  border-radius: 5px;
  margin-left: 50px;
  width: 75%;
  background-color: white
}

.deposit-funds-panel-title {
    border-bottom: 1.3px solid #e6ecf5;
    padding: 15px 20px;
    color: #515365;
    font-size: 17px;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
}

.deposit-funds-panel-list {
  margin: 15px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.deposit-funds-panel-item {
    display: flex;
    flex-direction: column;
    margin: 10px;
    border: 1.3px solid #e6ecf5;
    border-radius: 5px;
    align-items: center;
    padding: 30px 50px;
    transition: all 0.3s ease-out;
    height: 200px;
    width: 200px;
    justify-content: space-around;
    
}

.deposit-funds-panel-item:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}


.logo-big-3x {
  width: 70px;
  height: 70px;
}


.deposit-funds-panel-item >h4 {
  font-size: 18px;
  color: #515365;
  letter-spacing: 0.5px;
  font-weight: normal;
  line-height: 1.8;
  margin: 0px;
}

.deposit-funds-panel-item >span {
  font-size: 15px;
  color: #c0c2d0;
  letter-spacing: 0.5px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0px;
}

.deposit-funds-panel-item-btn {

  text-shadow: none;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 10%);
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  background-color: #ff9800;
  border-color: #ff9800;
  /* align-self: center; */
  width: 100%;
  padding: 5px 1vw;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: pointer;
}


@media  (max-width: 1400px) { 

    .deposit-funds-panel-wrapper {
        display: flex;
        justify-content: center;
    }


    .deposit-funds-panel {

        margin: 0px !important;
        width: 93%;
        background-color: white;
        padding-left: 0px !important;
      }

      .deposit-funds-panel-item {
        padding: 30px 6vw !important;
        margin: 6px !important;
      }

      .depositModal {
        position: fixed;

        left: 12vw !important;
 
        /* right: 200px; */

        margin: 0 !important
        
      }
 
      
      .depositModal > div {
        top: 10vh !important;
        /* top: 200px; */
        border: 1px solid rgba(155, 155, 155, 0.654);
        border-radius: 5px;
        padding: 30px 40px;
      /* position: fixed; */
      z-index: 300;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 94% !important;
      margin: 0 !important
      }

      .modal-content {

        width: 100% !important;
        display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0 !important
      }
      
      .modal-content_header {
  
        font-size: 12px !important;

      }

}

/* @media  (max-width: 1450px) { 

  .depositModal > div {

  width: 52% !important;

  }

 } */

.depositModal {
  position: fixed;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* width: 100%; */
  margin-left: 50px;
  width: 75%;
  /* right: 200px; */
  
}


.depositModal > div {

  top: 20vh;
  border: 1.3px solid rgba(202, 201, 201, 0.569);
  border-radius: 5px;
  padding: 0px 0px;
  /* border: 1px solid red; */
overflow: hidden;
position: fixed;
z-index: 300;
display: flex;
flex-direction: column;
align-items: center;
width: 32%;
background-color: #fefeff;
}

.modal-content {
  width: 100%;
  display: flex;
flex-direction: column;
align-items: center;
text-align: center;
box-sizing: border-box;
}

.modal-content > img {
  margin-bottom: 15px;
}

.modal-content  > h4 {
  color: #515365;
  font-size: 17px;
  letter-spacing: 0.5px;
  font-weight: normal;
  line-height: 1.5;
  margin: 10px  0px!important;
}

.handle_panel {

  width: 80% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding: 0!important;
  
  /* border-bottom: 1px solid red; */

}

.modal-content-close {
  width: 100%;
  border-top: 1px solid #e6ecf5;
  margin-top: 30px;
  padding: 15px 0;
  display: flex;
  justify-content: flex-end;
  /* margin-right: 40px; */
}

.modal-content_header {
  background-color: #ff9800;
  border: 1.3px solid #ff9800;
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
  border-radius: 3px;
  color: white;
  width: 100%;
  padding: 20px 0px;
  font-size: 14px;
  text-align: left;
  margin-bottom: 24px;
  box-sizing: border-box;
}
.modal-content_header > h2 {
  padding-left: 16px;
  letter-spacing: 0.5px;
  font-weight: normal;
  margin: 0px;
}


.handle_panel  > input
  {
  /* padding: 0!important; */
  border-bottom: none;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 6px;
  /* min-width: 50px;
  max-width: 635px; */
  width: 90% !important;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: #3b3f5c;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #bfc9d4;
  /* padding: 13px 0px 13px 46px; */
}


.btn-dep {
  margin-top: 10px;
  text-shadow: none;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 10%);
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #000!important;
  background-color: #FF9800;
  border-color: #FF9800;
  width: 100%;
  padding: 11px 14px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.5s;
 
}

.btn-dep:hover {
    background-color: #df8501;
    border-color: #df8501;
}

.btn-dep-mod {
  border-radius: 0.25rem !important;
}




.btn-custom {
  padding: 12px 20px!important;
  padding-left: auto!important;
    margin-right: 20px;
}