.createAccount {
    padding: 100px;
    border: 1px solid black;
}

.form-div {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
 

}

.form-container {
    display: block;
    width: 30%;
    padding: 25px;
    background: #fff;
    text-align: center;
    border-radius: 15px;
    border: 1px solid #e0e6ed;
    box-shadow: 0px 20px 40px 0 rgb(0 0 0 / 50%);

}

h1 {
    font-size: 32px;
    color: #3b3f5c;
    font-weight: 500;
    line-height: 1.2;
}

.signup-link {
    font-size: 13px;
    color: #888ea8;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 0;
}

.signup-link2 {
    font-size: 12px;
    color: #2049ff;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 0;
    cursor: pointer;
}

.signup-link > a {
    color: #1b55e2;
    /* border-bottom: 1px solid; */
}

.form-label {
    font-size: 10px;
    font-weight: 700;
    color: #3b3f5c;
    margin-bottom: 8px;
}

.field-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    box-sizing: border-box;
    position: relative;
    align-items: start;
    padding-bottom: 27px;
}

input {
    padding: 13px 0px 13px 46px;
    border-bottom: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    min-width: 40px;
    max-width: 635px;
    width: 90%;
    -ms-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    color: #3b3f5c;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid #bfc9d4;
    /* padding: 13px 0px 13px 46px; */
}

.field-container > svg{
    position: absolute;
    top: 32px;
    left: 12px;
    color: #888ea8;
    fill: rgba(0, 23, 55, 0.08);
    width: 20px;
    height: 20px;
    z-index: 100;
}

.field-container > label{
    font-size: 10px;
    font-weight: 700;
    color: #3b3f5cc0;
    margin-bottom: 7px;
}

.checkbox-box {
    display: flex;
     margin-bottom: 30px;

    
}

.checkbox-box >input {
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    width: 15px;
    height: 15px;
    margin: 0;
    padding: 0;
    
}

.checkbox-box > span {
   text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: #3b3f5cc0;
}

.btn {
    text-shadow: none;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 10%);
    text-align: center;
    vertical-align: middle;

    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff !important;
    background-color: #8dbf42;
    border-color: #8dbf42;
    /* align-self: center; */
    width: 100%;
    padding: 11px 14px;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}


.error-wrapper {
    position: absolute;
    margin-top: 66px;
    font-size: 12px;
    color: rgba(253, 88, 88, 0.897);
}

.error-wrapper-checkbox {
    position: absolute;
    margin-top: 30px;
    font-size: 12px;
    color: rgba(253, 88, 88, 0.897);
}


@media  (max-width: 1400px) { 

    .form-container {
        width: 65% !important;
        /* display: flex !important;
        flex-direction: column !important;
        align-items: center !important; */
    }

    .field-container {
        display: flex !important;
        justify-content: center !important;

    }

    input {
        padding: 13px 0px 13px 46px;
        border-bottom: none;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        border-radius: 6px;
        min-width: 50px;
        max-width: 1800px !important;

        -ms-transition: all 0.2s ease-in-out 0s;
        transition: all 0.2s ease-in-out 0s;
        color: #3b3f5c;
        font-weight: 600;
        font-size: 16px;
        border: 1px solid #bfc9d4;
        /* padding: 13px 0px 13px 46px; */
    }

    .error-wrapper {
        position: absolute;
        margin-top: 86px !important;
        font-size: 12px;
        color: rgba(253, 88, 88, 0.897) !important;
    }


  .logo-eye {


    padding-right: 4vw !important;
    padding-top: 28px !important;
  }


}

@media  (max-width: 900px) { 

    .logo-eye {


        padding-right: 2vw !important;
        padding-top: 28px !important;
      }

 }

@media  (max-width: 600px) { 

  

    .logo-eye {


        padding-right: 3vw !important;
        padding-top: 28px !important;

        width: auto;
      }

    .form-container {
        width: 82% !important;
        /* display: flex !important;
        flex-direction: column !important;
        align-items: center !important; */
    }

    input {
    
        /* min-width: 40px;
        max-width: 635px; */
        /* width: 70vw !important; */

    }



    .custom-field {
        width: 72vw !important;
    }




 }



 @media  (max-width: 480px) { 

    .error-wrapper-checkbox {

        margin-top: 50px !important;
   
    }
 }


 @media  (max-width: 400px) { 

    .custom-field {
        width: 70vw !important;
    }

    .form-div {
        margin: 0px 0px !important;
    }
  }



  .custom-select {
    border: none!important;
    color: #3b3f5c !important; 
  }

  .custom-select-header {
    border: none!important;
    background-color: rgba(0, 0, 0, 0) !important; 
    color: white !important
    
  }


  .select-box {

    display: flex;
    justify-content: flex-end;

  }


  .hiddenPswrd {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    
    
  }

  .logo-eye {
    /* padding-top: 240%; */
    opacity: 50%;
    width: 20px;
    height: 12px;
    padding-right: 16px;
    padding-bottom: 10px;
  }



  .bckgrnd_pic {
    display: absolute;
    position: fixed;
    height: 100vh;
    width: 100vw;

  }


  .bckgrnd_picture {
    width: 100%;
    height: 100%;
  }