
.withdraw-funds-panel-item {
    display: flex;
    flex-direction: column;
    margin: 10px;
    border: 1.3px solid #e6ecf5;
    border-radius: 5px;
    align-items: center;
    padding: 30px 50px;
    transition: all 0.3s ease-out;
    height: 200px;
    width: 300px;
    justify-content: space-around;
    
}

.withdraw-funds-panel-item:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}


.logo-big-4x {
  width: 70px;
  height: 70px;
}


.withdraw-funds-panel-item >h4 {
  font-size: 18px;
  color: #515365;
  letter-spacing: 0.5px;
  font-weight: normal;
  line-height: 1.8;
  margin: 0px;
}

.withdraw-funds-panel-item >span {
  font-size: 15px;
  color: #c0c2d0;
  letter-spacing: 0.5px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0px;
}

.withdraw-funds-panel-item-btn {

  text-shadow: none;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 10%);
  text-align: center;
  text-transform: uppercase;
  border: 1px solid transparent;
  line-height: 1.3;
  border-radius: 0.25rem;
  color: #000!important;
      background-color: #ff9800;
    border: 1px solid #ff9800;
  /* align-self: center; */
  width: 100%;
  padding: 5px 1vw; 
  height: 50px;
  font-size: 15px;
  letter-spacing: 2px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}



.react-responsive-modal-modal {
padding: 0px!important;
border-radius: 6px;;
}

.modal-padding {
  padding: 0px 30px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-gray-panel {
  width: 70%;
  padding: 5px 30px;
  background-color: rgba(222, 222, 222, 0.2);
    border-color: rgba(52, 211, 235, 0.3);
    color: rgb(71, 73, 74);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

 h4 {
  color: #515365;
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 0.5px;
  font-weight: normal;
  line-height: 1.5;
  font-size: 18px;
  text-align: center;
}

.modal-gray-list {
  margin-bottom: 20px;
}
.modal-gray-list > div {
  margin: 5px 0;
}

.black_span {
  color: black !important;
  font-weight: bolder;
  word-break: break-all;
}

.modal-gray-limits {
  margin: 20px 0px;
}

.modal-form-panel {
  border: 1px solid rgba(71, 73, 74, 0.241);
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  margin-top: 15px;
}

.modal-form-panel  > h4 {
  margin: 0px !important;
  padding: 10px 0px;
}



@media  (max-width: 850px) { 

  .modal-padding {
    padding: 0px 20px !important;
  
  }

  .modal-gray-panel {
    font-size: 14px !important;
    width: 90%;
    padding: 10px 20px;
    background-color: rgba(222, 222, 222, 0.2);
      border-color: rgba(52, 211, 235, 0.3);
      color: rgb(71, 73, 74);
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 10px 0 rgb(0 0 0 / 12%);
  }

  .modal-form-panel > h4 {
    font-size: 14px !important;
  }

  .modal-form-panel > input {
    width: 60vw !important
  }

  h4 {
    margin: 20px 0px;

  }

 }