.ReactTable .rt-thead.-header {
    text-align: center;
    font-size: 18px;
    padding: 18px;
    color: #515364 !important;
}

.ReactTable .rt-tbody {
    padding: 3px 18px;
    font-size: 16px;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgba(0,0,0,0.05);
    text-align: center;
    color: #4a4c5d !important;
    /* padding: 8px 0px; */
    margin: 0px !important;

}

.ReactTable .rt-thead .rt-resizable-header-content {
    /* overflow: hidden;
    text-overflow: ellipsis; */
    white-space: normal;
}

.ReactTable .rt-th, .ReactTable .rt-td {
    padding: 15px;
    white-space: normal !important;
    display: flex;
    justify-content: center;
    align-items: center;
}